import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ShippingCarriersPurchasesOrders, ShippingMethodsPurchasesOrders } from '@partsbadger/library';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
const ShipmentForm = observer((props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [hasCarrierIntegration, setHasCarrierIntegration] = React.useState(false);
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [boxes, setBoxes] = React.useState([
        {
            key: 'box_1',
        },
    ]);
    const { box } = props;
    const addBox = () => {
        setBoxes([
            ...boxes,
            {
                key: `box_${boxes.length + 1}`,
            },
        ]);
    };
    const removeBox = k => {
        setBoxes(boxes.filter(b => b.key !== k));
    };
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const keys = values.package_length ? Object.keys(values.package_length) : [];
                const payload = {
                    carrier: values.carrier,
                    shipping_method: values.shipping_method,
                    shipping_account: values.shipping_account,
                    notes: values.notes,
                    internal_notes: values.internal_notes,
                    shipping_cost: values._shipping_cost,
                    late_shipping_cost: values.late_shipping_cost,
                    tracking_number: values._tracking_number,
                    bill_to_customer: values.bill_to_customer,
                    opt_out_packing_slip: values.opt_out_packing_slip,
                    boxes_included: values.boxes_included,
                    account_type: values.shipping_account.toLowerCase() === 'pb' ? 'pb' : 'customer',
                    packages: keys.map(key => {
                        return {
                            package_length: values.package_length[key],
                            package_width: values.package_width[key],
                            package_height: values.package_height[key],
                            package_weight: values.package_weight[key],
                        };
                    }),
                };
                setIsLoading(true);
                props.handleSave(payload).finally(() => setIsLoading(false));
            }
        });
    };
    /**
     * Check if carrier integration is enabled
     */
    useEffect(() => {
        if (getFieldValue('carrier') && getFieldValue('carrier').toLowerCase() == 'fedex' && props.isTester) {
            setHasCarrierIntegration(true);
        }
        else {
            setHasCarrierIntegration(false);
        }
    }, [getFieldValue('carrier'), boxes.length]);
    const boxItems = boxes.map(({ key }, index) => (React.createElement("div", { className: 'flex flex-col md:flex-row', key: index },
        React.createElement(Form.Item, { label: index === 0 ? 'Length (IN)' : '' }, getFieldDecorator(`package_length[${key}]`, {
            rules: [{ required: hasCarrierIntegration, message: 'Field Required' }],
        })(React.createElement(InputNumber, { style: { width: '90%' } }))),
        React.createElement(Form.Item, { label: index === 0 ? 'Width (IN)' : '' }, getFieldDecorator(`package_width[${key}]`, {
            rules: [{ required: hasCarrierIntegration, message: 'Field Required' }],
        })(React.createElement(InputNumber, { style: { width: '90%' } }))),
        React.createElement(Form.Item, { label: index === 0 ? 'Height (IN)' : '' }, getFieldDecorator(`package_height[${key}]`, {
            rules: [{ required: hasCarrierIntegration, message: 'Field Required' }],
        })(React.createElement(InputNumber, { required: true, min: 0, style: { width: '90%' } }))),
        React.createElement(Form.Item, { label: index === 0 ? 'Weight (LB)' : '' }, getFieldDecorator(`package_weight[${key}]`, {
            rules: [{ required: hasCarrierIntegration, message: 'Field Required' }],
        })(React.createElement(InputNumber, { style: { width: '95%' }, step: 0.01 }))),
        React.createElement(Form.Item, { label: index === 0 ? 'Actions' : '' },
            React.createElement(Button, { type: "link", onClick: () => {
                    removeBox(key);
                } },
                React.createElement(CloseCircleOutlined, null))))));
    return (React.createElement(Form, { className: 'box-form' },
        React.createElement("div", { className: "flex flex-row" },
            React.createElement(Form.Item, { label: 'Carrier', help: box.carrier ? `Customer Requirement: ${box.carrier}` : '' }, getFieldDecorator('carrier', {
                rules: [{ required: true }],
                initialValue: box.carrier,
            })(React.createElement(ShippingCarriersPurchasesOrders, null))),
            React.createElement(Form.Item, { label: "Shipping Method", help: box.shipping_method ? `Customer Requirement: ${box.shipping_method}` : '' }, getFieldDecorator('shipping_method', {
                rules: [{ required: true }],
                initialValue: box.shipping_method,
            })(React.createElement(ShippingMethodsPurchasesOrders, null))),
            React.createElement(Form.Item, { label: "Shipping Account Number", help: 'PB if we will pay for the shipment' }, getFieldDecorator('shipping_account', {
                rules: [{ required: true }],
                initialValue: box.shipping_account,
            })(React.createElement(Input, { style: { width: '90%' }, onChange: value => {
                    value.target.value === '555692285' || value.target.value.toLowerCase() == 'pb'
                        ? setFieldsValue({
                            bill_to_customer: true,
                        })
                        : setFieldsValue({
                            bill_to_customer: false,
                        });
                } })))),
        hasCarrierIntegration && (React.createElement(React.Fragment, null,
            React.createElement("div", null, boxItems),
            React.createElement("div", { className: "flex flex-row items-center justify-center mt-4" },
                React.createElement(Button, { type: "dashed", onClick: addBox, style: { width: '60%' } },
                    React.createElement(PlusCircleOutlined, { type: "plus" }),
                    " Add Box")))),
        React.createElement("div", { className: 'flex flex-row' },
            React.createElement(Form.Item, { label: "Shipping Cost ($)", help: hasCarrierIntegration ? 'Leave empty to get automatically from the carrier' : '' },
                getFieldDecorator('_shipping_cost', {
                    rules: [{ required: !hasCarrierIntegration || boxes.length === 0 }],
                    initialValue: box.shipping_cost,
                })(React.createElement(InputNumber, { min: 0, step: 0.01, style: { width: 250 }, placeholder: hasCarrierIntegration ? 'Automatic' : '', onChange: value => {
                        (value && value > 0 && getFieldValue('shipping_account') === '555692285') ||
                            (value && value > 0 && getFieldValue('shipping_account')?.toLowerCase() == 'pb')
                            ? setFieldsValue({
                                bill_to_customer: true,
                            })
                            : setFieldsValue({
                                bill_to_customer: false,
                            });
                    } })),
                props.box.carrier_shipping_cost ? (React.createElement("div", null,
                    "Carrier Shipping Cost ",
                    props?.box?.carrier_shipping_cost)) : null),
            React.createElement(Form.Item, { label: "Tracking Number", help: hasCarrierIntegration ? 'Leave empty to get automatically from the carrier' : '' }, getFieldDecorator('_tracking_number', {
                rules: [{ required: !hasCarrierIntegration || boxes.length === 0 }],
                initialValue: box.tracking_number,
            })(React.createElement(Input, { style: { width: 250 }, placeholder: hasCarrierIntegration ? 'Automatic' : '' }))),
            React.createElement(Form.Item, { label: "Boxes included" }, getFieldDecorator('boxes_included', {
                rules: [{ required: true || boxes.length === 0 }],
                initialValue: 1,
            })(React.createElement(Input, { style: { width: 250 } })))),
        React.createElement("div", null,
            React.createElement(Form.Item, { label: "Customer Shipping Notes" }, getFieldDecorator('notes', {
                initialValue: box.notes,
            })(React.createElement(Input.TextArea, null))),
            React.createElement(Form.Item, { label: "Internal Shipping Notes" }, getFieldDecorator('internal_notes', {
                initialValue: box.internal_notes,
            })(React.createElement(Input.TextArea, null)))),
        props.outbound_destination !== 'Z' && (React.createElement("div", { className: "flex flex-row" },
            React.createElement(Form.Item, null, getFieldDecorator('bill_to_customer', {
                valuePropName: 'checked',
                initialValue: getFieldValue('shipping_account') === '555692285' ||
                    getFieldValue('shipping_account')?.toLowerCase() == 'pb'
                    ? true
                    : false,
            })(React.createElement(Checkbox, null, 'Bill to Customer?'))),
            React.createElement(Form.Item, { label: "LATE Shipping Cost" }, getFieldDecorator('late_shipping_cost', {
                initialValue: box.late_shipping_cost,
            })(React.createElement(InputNumber, { style: { width: '95%' } }))),
            React.createElement(Form.Item, null, getFieldDecorator('opt_out_packing_slip', {
                valuePropName: 'checked',
                initialValue: box.opt_out_packing_slip,
            })(React.createElement(Checkbox, null, "Opt Out of Packing Slip?"))))),
        props.box.id && (React.createElement("div", { className: " flex flex-row justify-center mt-4" },
            React.createElement(Form.Item, null, getFieldDecorator('resendNotification', {
                valuePropName: 'checked',
                initialValue: false,
            })(React.createElement(Checkbox, null, "Resend Shipment Notification?"))))),
        React.createElement("div", { className: "flex flex-col items-center justify-center mt-10" },
            React.createElement(Button, { type: "primary", loading: isLoading, onClick: handleSubmit }, props.box.id ? 'Update' : 'Create'))));
});
export default Form.create()(ShipmentForm);
